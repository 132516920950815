.footer-container{
    display: flex;
    flex-direction: column;
    background-color: var(--color-footer);
    color: var(--color-light);
    padding-bottom:2rem;
}

.links_media_container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 2rem 0;
}

.links_media{
    color: var(--color-grey);
    text-decoration: none;
    font-size: 30px;
  }

.links {
    color: var(--color-grey);
    text-decoration: none;
  }

.map-big{
    width: 100%;
    object-fit: contain;
    display: block;
}

.map-small{
    display: none;
}
.map-extrasmall{
    display: none;
}
.footer-container_content{
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 5rem 5rem 0 5rem;
}

.footer-container_content div h3{
    font-size: 26px;
    margin-bottom: 1rem;
}

.footer-container_content div p,
.footer-container_content-links-link{
    color: var(--color-grey);
    font-size: 18px;
    background-color: transparent;
    border: none;
    line-height: 32px;
    text-decoration: none;
}

.footer-container_content-info{
    display: flex;
    flex-direction: column;
}

.footer-container_content-info{
    width:40%
}

.footer-container_content-links{
    display: flex;
    flex-direction: column;

}

.footer-container_content-contact{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: start;
    width: 25%;
}

.footer-container_content-contact p{
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-all;
}

.contact-icon{
    margin-right: 1rem;
}

.footer-container_content-info p:last-child{
    text-align: end;
    margin-top: 1rem;
}

.footer-container_creators{
    width: 100%;
    text-align: center;
    padding: 0 3rem;
}

.visible{
    padding: 0 3rem;
}
.footer-container_creators p{
    color: var(--color-grey);
    font-size: 14px;
}

@media screen and (max-width: 1300px){
    .footer-container_content{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .footer-container_content-info{
        width: 100%;
        margin-bottom: 2rem;
    }
    .footer-container_content-links{
        width: 50%;
    }
    .footer-container_content-contact{
        width: 50%;
    }
  }

  @media screen and (max-width: 800px){
    .map-small{
        width: 100%;
        object-fit: contain;
        display: block;
    }
    
    .map-big{
        display: none;
    }  
}

  @media screen and (max-width: 700px){
    .footer-container_content{
        padding: 3rem
    }
    .footer-container_content-links{
        width: 100%;
        margin-bottom: 2rem;
    }
    .footer-container_content-contact{
        width: 100%;
    }
    .footer-container_content div p,button{
        font-size: 14px;
        line-height: 24px;
    }
    .footer-container_content div h3{
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .footer-container_creators p{
        font-size: 12px;
    }
    .footer-container_content-contact p{
        margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 500px){
    .map-extrasmall{
        width: 100%;
        object-fit: contain;
        display: block;
    }
    
    .map-small{
        display: none;
    }  
}