@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin: 0;
  font-family: var(--font-family);
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --font-family: 'Roboto Slab', serif;
  
  --color-dark: #040C18;
  --color-footer : #13161a;
  --color-feature : #1d2228;
  --color-grey:#cbcbcb;
  --color-light: #f9f9fa;
  --color-red: #d40303;
  --color-gold:#c19461;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-track {
  background-color: var(--color-footer);
  opacity: 0.8;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: var(--color-footer);

}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: var(--color-light);
}
