.accessory{
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: 500;
}

.accessory img{
    width: 20%;
    max-width: 55px;
    aspect-ratio: 3/2;
    margin-right: 0.5rem;
}

@media screen and (max-width: 1050px) {
    .accessory{
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .accessory{
        font-size: 14px;
    }
}