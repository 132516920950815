.header-container
{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/img/house.webp");
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center calc(25%);
    display: flex;
    flex-direction: column;
}

.header-container_button
{
    width:20%;
    margin: 50vh auto auto 4rem;
    min-width: 250px;
}