.feature-container
{
    display: flex;
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.evenDiv{
  flex-direction: row-reverse;
}

.evenDiv .feature-container_info{
  border-radius: 0px 20px 20px 0;
}

.oddDiv .feature-container_info{
  border-radius: 20px 0 0 20px;
}

.feature-container_photo
{
    display: flex;
    width: 50%;
    height: 100%;
    order: 2;
}

.feature-container_photo img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.feature-container_info {
    width: 50%;
    word-wrap: break-word;
    background-color: var(--color-feature);
    color: var(--color-light);
    padding: 3rem 2rem;
    outline: 2px solid var(--color-light);
    outline-offset: -10px;
  }


.feature-container_info h3{
  text-align: center;
  margin-bottom: 1rem;
}
.feature-container_info p{
  font-weight: 500;
}

@media (max-width: 1100px) {
    .feature-container_photo {
      width: 100%; /* Set both the photo and info divs to occupy full width */
        
    }
    .feature-container{
      flex-direction: column;
      margin-bottom: 3rem;
    }

    .oddDiv .feature-container_info,
    .evenDiv .feature-container_info{
      width: 90%;
      border-radius: 20px 20px 0 0;
      
    }
  }

  @media (max-width: 600px) {

    .oddDiv .feature-container_info,
    .evenDiv .feature-container_info{
      width: 100%;
    }
    .feature-container_photo img{
      border-radius: 0 0 20px 20px;
    }
    .feature-container_info{
      padding: 2rem;
    }
  }