.details-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url("/public/img/house.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center calc(25%);
    display: flex;
    flex-direction: column;
    background-color: var(--color-grey);
}

.details-container_content {
    margin: 1rem 0;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.details-container_content-photos,
.details-container_content-info {
    padding: 2rem;
    background-color: var(--color-light);
    border-radius: 20px;
}

.details-container_content-photos {
    width: 60%;
    display: flex;
    align-items: center;
}

.details-container_content-photos img {
    border-radius: 10px;
}

.details-container_content-info {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.details-container_content-info h2 {
    margin-bottom: 0.5rem;
}

.details-container_content-info h4 {
    text-align: end;
    font-size: 22px;
    margin: 0.5rem 0;
}

.details-container_content-info h4 span {
    font-weight: 400;
    font-size: 18px;
}

.info-list {
    margin-left: 1.5rem;
}

.info-list li {
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.booking-btn {

    background-color: #000;
}

.booking-btn_outside {
    margin: auto 0;
    width: 30%;
    min-width: 250px;
    align-self: center;
    display: none;
}

.details-section {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-section_description {
    width: 70%;
    margin-bottom: 3rem;
}

.details-section_description p{
    margin-top: 1rem;
}

.details-section_items {
    display: flex;
    width: 70%;
    justify-content: space-between;
}

.details-section_items div h4 {
    font-size: 20px;
    margin-bottom: 1rem;
}

.details-section_items-rooms,
.details-section_items-accessories {
    width: 48%;
}

.details-section_items-accessories div {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 1400px) {

    .details-section_description,
    .details-section_items {
        width: 85%;
    }
}

@media screen and (max-width: 1200px) {
    .details-container_content-info {
        width: 40%;
        border-radius: 0 20px 20px 0;
    }

    .details-container_content-photos {
        border-radius: 20px 0 0 20px;
    }

    .details-section_description,
    .details-section_items {
        width: 100%;
    }

}

@media screen and (max-width: 950px) {
    .booking-btn {
        display: none;
    }

    .booking-btn_outside {
        display: block;
    }

    .details-container_content-info {
        padding: 2rem 2rem 2rem 0;
    }

    .details-container_content-info h4 {
        text-align: end;
        font-size: 20px;
        margin: 0.5rem 0 0;
    }
    
}

@media screen and (max-width: 700px) {
    .details-container_content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .details-container_content-info {
        width: 80%;
        border-radius: 0 0 20px 20px;
        padding: 0 2rem 1rem;
    }

    .details-container_content-photos {
        width: 80%;
        border-radius: 20px 20px 0 0;
        padding: 2rem;
    }

    .booking-btn_outside {
        margin: 1rem 0;
    }

    .details-container_content {
        margin: 0;
    }

    .details-container_content-info h2 {
        font-size: 22px;
    }

    .details-container_content-info h4 {
        font-size: 20px;
    }

    .details-container_content-info h4 span {
        font-size: 16px;
    }
    .details-section_description p{
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .details-section_items{
        flex-direction: column;
    }
    .details-section_items-rooms,
    .details-section_items-accessories{
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .details-container_content-info h2 {
        font-size: 20px;
    }

    .details-container_content-info h4 {
        font-size: 18px;
    }

    .details-container_content-info h4 span {
        font-size: 16px;
    }

    .info-list li {
        font-size: 14px;
        font-weight: 500;
    }

    .details-container_content-info {
        padding: 0 1.5rem 1rem;
    }

    .details-container_content-photos {
        width: 80%;
        border-radius: 20px 20px 0 0;
        padding: 1.5rem;
    }

    .details-container_content-info,
    .details-container_content-photos {
        width: 100%;
    }
    .details-section{
        padding: 2rem;
    }
}

.image-gallery-icon {
    color: red;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 12px 12px #1a1a1a)''
}


@media(hover: hover)and (pointer: fine) {
    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.05);
        transition: 0.5s;
    }
}


.image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none
}


.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px
}

@media(max-width: 768px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 15px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media(max-width: 480px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 10px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.image-gallery-fullscreen-button {
    right: 0
}

.image-gallery-play-button {
    left: 0
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px
}

@media(max-width: 768px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px
    }
}

@media(max-width: 480px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px
    }
}


.image-gallery-left-nav {
    left: 0
}

.image-gallery-right-nav {
    right: 0
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0;
    padding: 20px
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px
}

@media(max-width: 768px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 15px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media(max-width: 480px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 10px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.image-gallery-fullscreen-button {
    right: 0
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative
}

.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px)
}

.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 100vh
}

.image-gallery-slide-wrapper {
    position: relative
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    width: calc(100% - 110px);
}

@media(max-width: 768px) {

    .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        width: calc(100% - 87px)
    }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.image-gallery-slide.image-gallery-center {
    position: relative
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;

    aspect-ratio: 3/2;
}



.image-gallery-thumbnails-wrapper {
    position: relative
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
    touch-action: pan-x
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    vertical-align: top;
    width: 100px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        width: 81px
    }
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    margin: 0 5px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        margin: 0 3px
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0
}

@media(max-width: 768px) {
    .image-gallery-thumbnails {
        padding: 3px 0
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;
    transition: 0.5s;
}

@media(max-width: 768px) {
    .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 81px
    }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 2px
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0
}



@media(hover: hover)and (pointer: fine) {
    .image-gallery-thumbnail:hover {
        outline: none;
        transform: translateX(3px);
    }
}