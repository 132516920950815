
.features-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.features-container h1{
    text-align: center;
    margin-bottom: 3rem;
}

.line{
    width: 100%;
    background-color: var(--color-dark);
    height: 5px;
    margin: 3rem 0;
}

.features-icons{
    font-size:30px;
    width: 100%;
    display: flex;
    justify-content: space-around;

}