.contact-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url("/public/img/house.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center calc(25%);
    display: flex;
    flex-direction: column;
    background-color: var(--color-grey);
}

.links_contacts {
    text-decoration: none;
    color: var(--color-dark);

}
.contact-container_content {
    margin-top: 3rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-self: center;
}

.contact-container_content-photos,
.contact-container_content-info {
    background-color: var(--color-light);
    border-radius: 20px;
    width: 45%;
}

.contact-container_content-photos {
    display: flex;
    align-items: center;
    padding: 2rem;
}

.contact-container_content-photos img {
    border-radius: 10px;
    width: 100%;
}

.contact-container_content-info{
    padding: 3rem;
    display: flex;
    flex-direction: column;
}

.contact-container_content-info h2, h3{
   margin-bottom: 0.5rem;
}

.contact-container_content-info p{
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-all;
}

.contact-container_content-info p:last-of-type {
    margin-bottom: 1rem;
}

.document-button_container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: auto;
}

.contact-booking-btn{
    width: 20%;
    min-width: 250px;
    align-self: flex-end;
    margin: 3rem 0;
    margin-right: 10%;
    background-color: black;
}

.document-button_container button{
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 500;
    text-underline-offset: 5px;
    cursor: pointer;
}

.document-button_container button:hover{
    text-decoration: underline;
}

.contact-footer{
        position:relative;
        width: 100%;
        bottom: 0;
        left: 0;
        margin-top: auto;
}

.arrow{    
    text-align: center;
    color: var(--color-light);
    padding: 2rem;
}
.arrow:hover{
    transition: 0.8s;
    transform: translateY(-10px) ;
}

@media screen and (max-width: 1200px){
    .contact-container_content-info{
        width: 50%;
        border-radius: 20px 0 0 20px;
        padding-right: 1rem;
    }
    .contact-container_content-photos{
        width: 50%;
        border-radius: 0 20px 20px 0;
    }
  }

  @media screen and (max-width: 850px){

    .contact-container_content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-container_content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-container_content-photos{
        width: 80%;
        border-radius: 0 0 20px 20px;
        padding: 0 2rem 2rem;
    }
    .contact-container_content-info{
        width:80%;
        border-radius: 20px 20px 0 0;
        padding: 2rem;
    }

    .contact-container_content{
        margin: 1rem;
    }
    .contact-container_content-info h2{
        font-size: 22px;
    }
     .contact-container_content-info h3{
         font-size: 18px;
     }
     .contact-booking-btn{
        margin: 1rem auto 0;
     }
     .contact-footer{
        position: relative;
        margin-top: 2rem;
     }
     .arrow{
        padding: 1rem;
     }
  }

  @media screen and (max-width: 550px){
    .contact-container_content-info h2{
        font-size: 20px;
    }
     .contact-container_content-info h3{
         font-size: 16px;
     }
     .contact-container_content-info p{
         font-size: 14px;
     }
     .info-list li{
        font-size: 14px;
        font-weight: 500;
     } 

    .contact-container_content-info,
    .contact-container_content-photos{
        width: 100%;
    }
  }

  @media screen and (max-width: 400px){
    .document-button_container{
        flex-direction: column;
        align-items: flex-start;
    }
    .document-button_container button{
        margin-bottom: 0.5rem;
    }
  }

