.apartment-container{
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
    transition: 0.5s;
    text-decoration: none;
    color: var(--color-dark);
}

.apartment-container h3{
    margin: 1rem 0;
    font-size: 26px;
}

.apartment-icon{
    margin-right: 0.5rem;
}

.apartment-container p{
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.apartment-container img{
    width:100%;
    border-radius: 10px;
}

.apartment-container:hover{
    transform: translateY(-10px);
}

@media screen and (max-width: 1400px){
    .apartment-container h3{
        font-size: 22px;
    }
    .apartment-container p{
        font-size: 16px;
    }
  }

@media screen and (max-width: 1200px){
    .apartment-container{
        width: 40%;
    }
    .apartment-container h3{
        margin: 1rem 0 0.5rem 0;
    }
  }

  @media screen and (max-width: 800px){
    .apartment-container{
        width: 80%;
        padding: 0;
    }
  }
  @media screen and (max-width: 400px){
    .apartment-container{
        width: 85%;
        padding: 0;
    }
  }