.booking-button
{
    display: flex;
    padding: 1rem 2rem;
    color: white;
    text-decoration: none;
    border: 0.5px solid white;
    cursor: pointer;
    min-width: 250px;
    background-color: rgb(0, 0, 0);
    font-size: 20px;
    align-items: center;
    font-weight: 500;
}

.button-icon{
    margin-left: 0.5rem;
}

.booking-button_container a {
    text-decoration: none;
  }