.apartments-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top:3rem ;
}

.apartments-container_link{
    color: var(--color-dark);
}

@media screen and (max-width: 1200px){
    .apartments-container{
        justify-content: space-evenly;
    }
  }