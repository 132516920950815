.reservation-container{
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-image: url("/public/img/house.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center calc(25%);
}

.back-button{
    color: #5e5e5e;
    position: absolute;
    z-index: 1000;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 35px;
    cursor: pointer;
    transition: 0.5s;
}

.back-button:hover{
    transform: scale(1.1);
}
